export const extraMap = {
  PLACEHOLDER: {
    lineBold: false,
    bold: false,
    hideBottomLine: false,
    showForNull: true,
    whetherExpanded: false,
    popover: false,
    hideTopLine: false
  },
  NORMAL: {
    lineBold: false,
    bold: false,
    hideBottomLine: false,
    showForNull: false,
    whetherExpanded: true,
    popover: true,
    hideTopLine: false
  },
  AGG: {
    lineBold: false,
    bold: false,
    hideBottomLine: false,
    showForNull: true,
    whetherExpanded: false,
    popover: true,
    hideTopLine: false
  },
  CHILDREN: {
    lineBold: false,
    bold: false,
    hideBottomLine: false,
    showForNull: false,
    whetherExpanded: true,
    popover: true,
    hideTopLine: false,
    reverse: false
  }
}

export const codesConfigMap: any = {
  'balance-sheet': {
    '100': {
      name: 'Assets',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 2
    },
    '100-agg': {
      name: 'Total assets',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '200': {
      name: 'Liabilities',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 2
    },
    '200-agg': {
      name: 'Total liabilities',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '300': {
      name: 'Equity',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 2
    },
    '300-agg': {
      name: 'Total equity',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '200-300-agg': {
      name: 'Total liabilities and equity',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    }
  },
  'income-statement': {
    '400': {
      name: 'Revenue',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 2
    },
    '400-agg': {
      name: 'Total revenue',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '500': {
      name: 'Expenses',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 2
    },
    '500-agg': {
      name: 'Total expenses',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '400-500-agg': {
      name: 'Income from operations',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '600': {
      name: 'Other income and expenses',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 2
    },
    '600-crypto-agg': {
      name: 'Net gain(loss) from crypto activities',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    '600-agg': {
      name: 'Total non-operating or other',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'all-agg': {
      name: 'Net income',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    }
  },
  'cash-flow-statement': {
    'cashflow-100': {
      name: 'Cash flows from operating activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 3
    },
    'cashflow-100-general-in': {
      name: 'Other cash inflows from operating activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'cashflow-100-general-out': {
      name: 'Other cash outflows from operating activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'cashflow-100-agg': {
      name: 'Net cash from operating activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'cashflow-200': {
      name: 'Cash flows from financing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 3
    },
    'cashflow-200-general-in': {
      name: 'Other cash inflows from financing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'cashflow-200-general-out': {
      name: 'Other cash outflows from financing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'cashflow-200-agg': {
      name: 'Net cash from financing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'cashflow-300': {
      name: 'Cash flows from investing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 3
    },
    'cashflow-300-exchange-rate-fluctuation': {
      name: 'Exchange rate fluctuation',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 5
    },
    'cashflow-300-general-in': {
      name: 'Other cash inflows from investing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'cashflow-300-general-out': {
      name: 'Other cash outflows from investing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'cashflow-300-agg': {
      name: 'Net cash from investing activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'cashflow-all-agg': {
      name: 'Net (decrease) increase in cash and cash equivalents',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'cashflow-begin': {
      name: 'Cash and cash equivalents at the beginning of period',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'cashflow-end': {
      name: 'Cash and cash equivalents at the end of period',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    }
  },
  'roll-forward': {
    'rollforward-begin': {
      name: 'Total fair value at the beginning of period',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-100': {
      name: 'Assets acquired',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 3
    },
    'rollforward-100-general-journal': {
      name: 'Other activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'rollforward-100-agg': {
      name: 'Total assets acquired',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-200': {
      name: 'Assets disposed',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 3
    },
    'rollforward-200-general-journal': {
      name: 'Other activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: extraMap.NORMAL,
      hasCurrency: true,
      hasChildren: true,
      currencyPosition: 4
    },
    'rollforward-200-agg': {
      name: 'Total assets disposed',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-300': {
      name: 'Gains(Realized and Unrealized)',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-400': {
      name: 'Losses(Realized and Unrealized)',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-end': {
      name: 'Total Fair Value at the end of period',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-gain-loss': {
      name: 'Realized vs unrealized gains and losses',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'PLACEHOLDER',
      extra: { ...extraMap.PLACEHOLDER, lineBold: true },
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-500': {
      name: 'Gain (Loss) on crypto activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-600': {
      name: 'Unrealized gain (loss) - crypto fair value measurement',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    },
    'rollforward-500-600-agg': {
      name: 'Net gain(loss) from crypto activities',
      codeDefine: 'JOURNAL_TYPE',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: true,
      currencyPosition: 0
    }
  },
  restrictions: {
    'restriction-100': {
      name: 'Unrestricted crypto assets',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: false,
      hasChildren: false,
      currencyPosition: 0
    },
    'restriction-100-00': {
      name: 'Unrestricted crypto assets',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200': {
      name: 'Restricted crypto assets',
      codeDefine: 'COA',
      showType: 'PLACEHOLDER',
      extra: extraMap.PLACEHOLDER,
      hasCurrency: false,
      hasChildren: false,
      currencyPosition: 0
    },
    'restriction-200-00': {
      name: 'Crypto assets restricted by staking protocols',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200-01': {
      name: 'Crypto assets restricted by liquidity providing protoclos',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200-02': {
      name: 'Crypto assets restricted by farming protocols',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200-03': {
      name: 'Crypto assets restricted by lender',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200-04': {
      name: 'Crypto assets restricted by custodians',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200-05': {
      name: 'Crypto assets restricted by locking protocols',
      codeDefine: 'COA',
      showType: 'NORMAL',
      extra: { ...extraMap.NORMAL, showForNull: true },
      hasCurrency: true,
      hasChildren: false,
      currencyPosition: 3
    },
    'restriction-200-agg': {
      name: 'Total restricted crypto assets',
      codeDefine: 'COA',
      showType: 'AGG',
      extra: extraMap.AGG,
      hasCurrency: false,
      hasChildren: false,
      currencyPosition: 0
    }
  }
}

export const nameMapIS: { [key: string]: any } = {
  '400': 'Revenue',
  '400-agg': 'Total revenue',
  '500': 'Expenses',
  '500-agg': 'Total expenses',
  '400-500-agg': 'Income from operations',
  '600': 'Other income and expenses',
  '600-crypto-agg': 'Net gain(loss) from crypto activities',
  '600-agg': 'Total non-operating or other',
  'all-agg': 'Net income'
}

export const codesMap: any = {
  'balance-sheet': ['100', '100-agg', '200', '200-agg', '300', '300-agg', '200-300-agg'],
  'income-statement': [
    '400',
    '400-agg',
    '500',
    '500-agg',
    '400-500-agg',
    '600',
    '600-crypto-agg',
    '600-agg',
    'all-agg'
  ],
  'cash-flow-statement': [
    'cashflow-100',
    'cashflow-100-general-in',
    'cashflow-100-general-out',
    'cashflow-100-agg',
    'cashflow-200',
    'cashflow-200-general-in',
    'cashflow-200-general-out',
    'cashflow-200-agg',
    'cashflow-300',
    'cashflow-300-exchange-rate-fluctuation',
    'cashflow-300-general-in',
    'cashflow-300-general-out',
    'cashflow-300-agg',
    'cashflow-all-agg',
    'cashflow-begin',
    'cashflow-end'
  ],
  'roll-forward': [
    'rollforward-begin',
    'rollforward-100',
    'rollforward-100-general-journal',
    'rollforward-100-agg',
    'rollforward-200',
    'rollforward-200-general-journal',
    'rollforward-200-agg',
    'rollforward-300',
    'rollforward-400',
    'rollforward-end',
    'rollforward-gain-loss',
    'rollforward-500',
    'rollforward-600',
    'rollforward-500-600-agg'
  ],
  restrictions: [
    'restriction-100',
    'restriction-100-00',
    'restriction-200',
    'restriction-200-00',
    'restriction-200-01',
    'restriction-200-02',
    'restriction-200-03',
    'restriction-200-04',
    'restriction-200-05',
    'restriction-200-agg'
  ]
}

export const mockBS = JSON.parse(
  '{"status":"success","data":{"updatedOn":"2024-01-17T09:13:48.928Z","list":[{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2023-12-31","period":"MONTH","fieldValues":[{"fieldId":"100-00","value":0},{"fieldId":"100-01","value":2},{"fieldId":"100-01-usd","value":1},{"fieldId":"100-01-eth","value":1},{"fieldId":"100-02","value":0},{"fieldId":"100-agg","value":1},{"fieldId":"200-01","value":4},{"fieldId":"200-02","value":6},{"fieldId":"200-agg","value":10},{"fieldId":"300-01","value":0},{"fieldId":"300-02","value":0},{"fieldId":"300-agg","value":0},{"fieldId":"200-300-agg","value":10}],"status":"NORMAL"},{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2024-01-01","period":"MONTH","fieldValues":[{"fieldId":"100-00","value":0},{"fieldId":"100-01","value":1},{"fieldId":"100-02","value":0},{"fieldId":"100-agg","value":1},{"fieldId":"200-01","value":4},{"fieldId":"200-02","value":6},{"fieldId":"200-agg","value":10},{"fieldId":"300-01","value":0},{"fieldId":"300-02","value":0},{"fieldId":"300-agg","value":0},{"fieldId":"200-300-agg","value":10}],"status":"NORMAL"}],"errorList":[{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2023-12-31","period":"MONTH","fieldValues":[{"fieldId":"100-00","value":0}],"status":"NORMAL"}]}}'
)

export const mockIS = JSON.parse(
  '{"status":"success","data":{"updatedOn":"2024-01-17T09:13:48.928Z","list":[{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2023-12-31","period":"MONTH","fieldValues":[{"fieldId":"400-00","value":0},{"fieldId":"400-01","value":2},{"fieldId":"400-01-usd","value":1},{"fieldId":"400-01-eth","value":1},{"fieldId":"400-02","value":0},{"fieldId":"400-agg","value":1},{"fieldId":"500-01","value":4},{"fieldId":"500-02","value":6},{"fieldId":"500-agg","value":10},{"fieldId":"400-500-agg","value":11},{"fieldId":"600-01","value":0},{"fieldId":"600-02","value":0},{"fieldId":"600-agg","value":0},{"fieldId":"600-crypto-agg","value":10},{"fieldId":"all-agg","value":10}],"status":"NORMAL"}],"errorList":[]}}'
)

export const mockCFS = JSON.parse(
  '{"status":"success","data":{"updatedOn":"2024-01-17T09:13:48.928Z","list":[{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2023-12-31","period":"MONTH","fieldValues":[{"fieldId":"cashflow-100-10000","value":2},{"fieldId":"cashflow-100-10001","value":1},{"fieldId":"cashflow-100-10001-usd","value":1},{"fieldId":"cashflow-100-10001-eth","value":1},{"fieldId":"cashflow-100-general-in","value":1},{"fieldId":"cashflow-100-general-out","value":0},{"fieldId":"cashflow-100-agg","value":1},{"fieldId":"cashflow-200-10000","value":2},{"fieldId":"cashflow-200-10001","value":1},{"fieldId":"cashflow-200-general-in","value":1},{"fieldId":"cashflow-200-general-out","value":0},{"fieldId":"cashflow-200-agg","value":1},{"fieldId":"cashflow-300-10000","value":2},{"fieldId":"cashflow-300-10001","value":1},{"fieldId":"cashflow-300-exchange-rate-fluctuation","value":1},{"fieldId":"cashflow-300-general-in","value":1},{"fieldId":"cashflow-300-general-out","value":0},{"fieldId":"cashflow-300-agg","value":1},{"fieldId":"cashflow-all-agg","value":1},{"fieldId":"cashflow-begin","value":1},{"fieldId":"cashflow-end","value":1}],"status":"NORMAL"}],"errorList":[]}}'
)

export const mockRF = JSON.parse(
  '{"status":"success","data":{"updatedOn":"2024-01-17T09:13:48.928Z","list":[{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2023-12-31","period":"MONTH","fieldValues":[{"fieldId":"rollforward-begin","value":0},{"fieldId":"rollforward-100-10000","value":2},{"fieldId":"rollforward-100-10001","value":1},{"fieldId":"rollforward-100-10001-usd","value":1},{"fieldId":"rollforward-100-10001-eth","value":1},{"fieldId":"rollforward-100-general-journal","value":1},{"fieldId":"rollforward-100-agg","value":1},{"fieldId":"rollforward-200-10000","value":2},{"fieldId":"rollforward-200-10001","value":1},{"fieldId":"rollforward-200-general-journal","value":1},{"fieldId":"rollforward-200-agg","value":1},{"fieldId":"rollforward-300","value":0},{"fieldId":"rollforward-400","value":2},{"fieldId":"rollforward-end","value":1},{"fieldId":"rollforward-500","value":1},{"fieldId":"rollforward-600","value":1},{"fieldId":"rollforward-500-600-agg","value":1}],"status":"NORMAL"}],"errorList":[]}}'
)

export const mockRCA = JSON.parse(
  '{"status":"success","data":{"updatedOn":"2024-01-17T09:13:48.928Z","list":[{"entityId":"dtmpXKs3FCMPKb6SQjOExznap5owRrG0","date":"2023-12-31","period":"MONTH","fieldValues":[{"fieldId":"restriction-100-00","value":2},{"fieldId":"restriction-100-00-usd","value":2},{"fieldId":"restriction-200-00","value":1},{"fieldId":"restriction-200-agg","value":1}],"status":"NORMAL"}],"errorList":[]}}'
)
